import { html } from 'lit';

const checkoutStyles = html`<style>
  #ps__checkbox_container {
    margin-top: 1.5em;
  }

  #ps__footer {
    padding-top: 1em;
    line-height: 1.2;
    font-size: 10px;
    color: rgb(85, 85, 85);
  }

  #ps__footer a {
    color: inherit;
    text-transform: uppercase;
    text-decoration: underline;
  }

  a {
    color: inherit;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  .shopify {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', sans-serif;
    line-height: 1.3em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .recharge input[type='checkbox'],
  .carthook input[type='checkbox'] {
    -webkit-transition: box-shadow 0.1s cubic-bezier(0.3, 0, 0, 1.2),
      border-color 0.1s cubic-bezier(0.3, 0, 0, 1.2);
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    height: 18px;
    transition: box-shadow 0.1s cubic-bezier(0.3, 0, 0, 1.2),
      border-color 0.1s cubic-bezier(0.3, 0, 0, 1.2);
    vertical-align: -4px;
    width: 18px;
  }

  #ps__phone_container {
    margin-top: 0.93em;
  }
</style>`;

export default checkoutStyles;
