/* eslint-disable camelcase */
import { captureException } from '@sentry/browser';
import { nanoid } from 'nanoid';
import {
  POPUP_COOKIE_NAME,
  POPUP_EVENT_TYPES,
  POPUP_IMPRESSION_MESSAGE_TYPE,
  SESSION_COOKIE_NAME,
  BODY_PARAM_SEPARATOR,
  MESSAGING_URL_PREFIX,
  SUBSCRIBER_ID_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
  TWOTAP_ID_COOKIE_NAME,
  V2_OPT_IN_SOURCES,
} from './constants';
import { setCookieOnParentDocument } from './iframe';
import {
  WEBHOOK_URL,
  delay,
  fetchWithTimeout,
  setCookie,
  CLOUDFLARE_BUSINESS_PLAN_URL,
} from './utility';
import {
  popupStateStore,
  getFriendlyNameForPopupStatus,
  configStateStore,
} from '../widget/common/helpers/stateManager';
import { optIn } from '../sdk/core/onsite-opt-in/service';

window.setParentLocation = (href: string) => {
  window.top?.postMessage(
    {
      NavigateToUri: {
        name: 'NavigateToUri',
        href,
      },
    },
    '*',
  );
};

export const setPopupCookie = (state: string, popupId: number | string) => {
  popupStateStore.updateState({
    status: getFriendlyNameForPopupStatus(state),
  });
  setCookieOnParentDocument(`${POPUP_COOKIE_NAME}_${popupId}`, state, 3650);
};

// Be careful when changing the name or structure of this event. Some of our enterprise customers
// listen for this event in order to add custom javascript after an email is collected.
export const collectedEmailEvent = (email: string) => {
  window.parent.postMessage(
    {
      CollectedEmailEvent: {
        name: 'CollectedEmailEvent',
        email,
      },
    },
    '*',
  );
};

export const setSessionCookie = () => {
  const sessionId = nanoid();
  setCookieOnParentDocument(SESSION_COOKIE_NAME, sessionId, 3650);

  return sessionId;
};

export const setTwoTapCookie = (twoTapId: string | null) => {
  if (!twoTapId) {
    return;
  }

  window.ps__server_id = twoTapId;
  setCookieOnParentDocument(TWOTAP_ID_COOKIE_NAME, twoTapId);
};

const setToken = (value: string) => {
  window.ps__token = value;
  setCookieOnParentDocument(TOKEN_COOKIE_NAME, value);
};

const setSubscriberId = (value: number) => {
  window.ps__subscriber_id = value;
  setCookieOnParentDocument(SUBSCRIBER_ID_COOKIE_NAME, value, 3650);
};

interface ConfigureSubscriptionEnvironmentProps {
  isIframe?: boolean;
  subscriberId?: number;
  token?: string;
}

export const configureSubscriptionEnvironment = ({
  isIframe = true,
  subscriberId,
  token,
}: ConfigureSubscriptionEnvironmentProps) => {
  if (!isIframe) {
    setCookie(TOKEN_COOKIE_NAME, token, 3650);
    if (subscriberId) {
      setCookie(SUBSCRIBER_ID_COOKIE_NAME, subscriberId, 3650);
    }
    return;
  }

  if (token) {
    setToken(token);
  }

  if (subscriberId) {
    setSubscriberId(subscriberId);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendAttributesEvent = (data: any) =>
  fetch(`${CLOUDFLARE_BUSINESS_PLAN_URL}/api/subscriber/attributes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  });

export const trackPopupEvent = (
  eventType: string,
  shopId: number,
  popupId: number | string,
  country: string | null,
) => {
  const configManager = configStateStore.getState();
  if (configManager.isPreview) return Promise.resolve({});

  const url = `${WEBHOOK_URL}/v1/subscribers`;
  const data = {
    country: country?.toUpperCase(),
    event: eventType,
    popup_id: popupId,
    event_type: 'popup',
    shop_id: shopId,
  };

  try {
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });
  } catch (originalError) {
    captureException(
      new TypeError(`Failed to track ${eventType} event`, {
        cause: originalError,
      }),
    );
  }
};

const sendPopupImpressionEventToBrowser = (
  popupId: number,
  popupName: string,
  isReopen: boolean,
) => {
  window.parent.postMessage(
    {
      type: POPUP_IMPRESSION_MESSAGE_TYPE,
      popupId,
      popupName,
      isReopen,
    },
    '*',
  );
};

export const impressionEvent = (
  shopId: number,
  country: string,
  popupId: number,
  popupName: string,
  isReopen = false,
) => {
  trackPopupEvent(POPUP_EVENT_TYPES.IMPRESSION, shopId, popupId, country);

  if (isReopen) {
    trackPopupEvent(POPUP_EVENT_TYPES.ENGAGEMENT, shopId, popupId, country);
  }

  sendPopupImpressionEventToBrowser(popupId, popupName, isReopen);
};

export const acceptEvent = (
  shopId: number,
  country: string,
  phone: string,
  popupId: number,
  sessionId: string,
) =>
  optIn({
    countryCode: country,
    id: popupId,
    phoneNumber: phone,
    sessionId,
    shopId,
    source: V2_OPT_IN_SOURCES.DESKTOP,
  });

export const appendServerIdToOptInMessage = (
  message: string,
  serverId: string | null,
  serverIdAbbreviation = 'sid',
) => {
  if (!serverId) {
    return message;
  }

  const keywordRegex = /(ref:\s\S+)(\s?)/;
  const matches = keywordRegex.exec(message);
  if (!matches || matches.length !== 3) {
    return `${message} ${serverIdAbbreviation}: ${serverId}`;
  }
  const [, keyword] = matches;
  return message.replace(
    keywordRegex,
    `${keyword} ${serverIdAbbreviation}: ${serverId} `,
  );
};

export const formatSmsLink = (phone: string, body: string, country: string) => {
  const ua = navigator.userAgent.toLowerCase();

  let newPhone = phone;

  if (country === 'US' || country === 'CA') {
    // replace leading + in phone number
    newPhone = String(newPhone).replace(/^\+/, '');
    if (newPhone.length === 11 && newPhone.charAt(0) === '1') {
      newPhone = newPhone.slice(1);
    }
  }

  let location;

  if (ua.includes('pixel')) {
    location = `${MESSAGING_URL_PREFIX}//${newPhone}/?${BODY_PARAM_SEPARATOR}${encodeURIComponent(
      body,
    )}`;
  } else if (ua.includes('chrome') || ua.includes('android')) {
    location = `${MESSAGING_URL_PREFIX}${newPhone}?${BODY_PARAM_SEPARATOR}${encodeURIComponent(
      body,
    )}`;
  } else {
    location = `${MESSAGING_URL_PREFIX}${newPhone}${BODY_PARAM_SEPARATOR}${encodeURIComponent(
      body,
    )}`;
  }

  return location;
};

export const acceptEmailEvent = async (
  shopId: number,
  email: string,
  sessionId: string,
  country: string,
  popupId: number,
  source: string,
) => {
  window.ps__email = email;

  trackPopupEvent(POPUP_EVENT_TYPES.SUBMIT_EMAIL, shopId, popupId, country);
  return sendAttributesEvent({
    shop_id: shopId,
    source,
    session_id: sessionId,
    email,
    subscriber_id: window.ps__subscriber_id,
    token: window.ps__token,
    server_id: window.ps__server_id,
    popup_id: popupId,
  });
};

export const acceptMobileEvent = async (
  country: string,
  phone: string,
  smsMessage: string,
) => {
  window.setParentLocation(formatSmsLink(phone, smsMessage, country));

  // not sure why we delay here, parity with legacy code
  await delay(500);
};

export const getTwoTapSessionId = async (
  popupId: number,
  sessionId: string,
) => {
  try {
    const data = {
      popup_id: popupId,
      session_id: sessionId,
    };
    const response = await fetchWithTimeout(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/sdk/session/server_id`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      },
      2000,
    );
    return response.ok ? response.text() : null;
  } catch {
    return null;
  }
};
