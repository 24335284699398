import { html } from 'lit';

const phoneInput = html`
  <div id="ps__phone_container" class="fieldset" hidden>
    <div id="ps_phone_field_container" class="field">
      <div id="ps_phone_field_wrapper" class="field__input-wrapper">
        <label for="ps_phone_number" class="field__label field__label--visible"
          >Phone Number</label
        >
        <input
          id="ps_phone_number"
          name="ps_phone_number"
          type="tel"
          placeholder="Phone Number"
          class="field__input"
        />
      </div>
    </div>
  </div>
`;

export default phoneInput;
