import './polyfills';
import initializeSdk from './sdk/postscript-sdk';
import { getCurrentScript, getUrlParameter } from './helpers/scripts';
import { fetchConfig } from './helpers/utils';
import { getMyShopifyDomain } from './widget/helpers/get-shopify-domain-helper';
import { configStateStore } from './widget/common/helpers/stateManager';

const setUpSdk = async () => {
  try {
    const script = getCurrentScript();
    const queryString = `?${script.src.replace(/^[^?]+\??/, '')}`;
    const shopId = getUrlParameter(queryString, 'shopId');
    const shopDomain = getMyShopifyDomain();

    if (!shopId && !shopDomain) {
      // eslint-disable-next-line no-console
      console.log('Error: no shopId or shopDomain available');
      return; // exit early if we don't have shopId or shopDomain
    }

    const config = await fetchConfig(shopId, shopDomain);

    configStateStore.updateState({
      flags: config?.data?.flags?.toString(),
      shopId,
      shopShop: config?.data?.shop_shop, // eslint-disable-line
      disclaimer: config?.data?.disclaimer,
    });

    initializeSdk(shopId);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error initializing sdk: ', err);
  }
};

// Wait for the document to load before initializing the SDK & checking for shopDomain
// to ensure that the Shopify object exists
(async function initialize() {
  if (document.readyState === 'complete') {
    setUpSdk();
    return;
  }

  window.addEventListener('load', () => {
    setUpSdk();
  });
})();
