import { html } from 'lit';
import { DEFAULT_TERMS } from '../../helpers/constants';
import checkoutStyles from './checkoutStyles';
import phoneInputCarthook from './phoneInputCarthook';

const checkoutCarthook = ({
  checked,
  label,
  handleOptInClicked,
  hostname,
  shouldRenderPhoneInput,
}) => html`
  ${checkoutStyles}
  <div
    id="ps__container"
    class="col-xs-12 col-12 checkbox-wrap carthook"
    style="padding-bottom: 12px; height: auto;"
  >
    <input
      class="ch-checkbox checkbox-buyer-accepts-marketing"
      id="ps_accepts_sms"
      name="ps_accepts_sms"
      type="checkbox"
      ?checked=${checked}
      @change=${({ target: { checked } }) => handleOptInClicked(checked)}
      data-cy="accepts-sms-checkbox"
    />
    <label class="ch-label" for="ps_accepts_sms">
      <span class="ch-custom-checkbox" data-ch-type="text"></span>
      <span data-ch-type="text" class="ch-custom-label">
        <span data-ch-type="text">${label}</span>
      </span>
    </label>
    <div id="ps__footer" data-ch-type="text">${DEFAULT_TERMS(hostname)}</div>
    ${shouldRenderPhoneInput ? phoneInputCarthook : ''}
  </div>
`;

export default checkoutCarthook;
