import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { CLOUDFLARE_BUSINESS_PLAN_URL, getCookie, setCookie } from './utility';
import {
  SUBSCRIBER_ID_COOKIE_NAME,
  SUBSCRIBER_PREVIOUS_SESSION_COOKIE_NAME,
} from './constants';
import { setCookieOnParentDocument } from './iframe';

export const calculateFingerprintId = async (): Promise<string> => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
};

export const submitFingerprintData = (
  isIframe: boolean,
  shopId?: string,
  subscriberIdFromIframe?: string,
): void => {
  const subscriberId = isIframe
    ? subscriberIdFromIframe
    : getCookie(SUBSCRIBER_ID_COOKIE_NAME);
  if (!subscriberId) return;

  const hasSubscriberPreviousSessionCookie = isIframe
    ? false
    : getCookie(SUBSCRIBER_PREVIOUS_SESSION_COOKIE_NAME);
  if (hasSubscriberPreviousSessionCookie) return;

  const apiEndpoint = `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/opt_in/fingerprint`;

  const fingerprintIdPromise = calculateFingerprintId();

  fingerprintIdPromise
    .then((fingerprint) => {
      const data = {
        shop_id: shopId,
        subscriber_id: subscriberId,
        fingerprint: [
          {
            version: 2,
            data: fingerprint,
          },
        ],
      };

      return fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    })
    .then(() => {
      // Once we have successfully sent the fingerprint, we set a cookie to prevent
      // sending the fingerprint again during the same session.
      if (isIframe) {
        setCookieOnParentDocument(
          SUBSCRIBER_PREVIOUS_SESSION_COOKIE_NAME,
          'true',
          0,
          4,
        );
      } else {
        setCookie(SUBSCRIBER_PREVIOUS_SESSION_COOKIE_NAME, 'true', 0, 4);
      }
    })
    .catch(() => null);
};
