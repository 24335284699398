import { getCookie } from '../../helpers/utility';
import { configStateStore } from '../../widget/common/helpers/stateManager';

export const KLAVIYO_COOKIE_NAME = '__kla_id';
export const SHOPIFY_COOKIE_NAME = '_shopify_y';
export const IDENTITY_RESOLUTION_FLAG = 'identity_resolution';

const EMPTY_IDENTIFIERS = {};

interface ThirdPartyIdentifiers {
  kla_exchange_id?: string;
  shopify_y?: string;
}

const getKlaviyoIds = (): ThirdPartyIdentifiers => {
  const identifiers: ThirdPartyIdentifiers = {};
  const klaviyoCookie = getCookie(KLAVIYO_COOKIE_NAME);
  if (!klaviyoCookie) {
    return identifiers;
  }

  try {
    const klaviyoCookieJson = JSON.parse(atob(klaviyoCookie));
    identifiers.kla_exchange_id = klaviyoCookieJson.$exchange_id;
  } catch {
    return identifiers;
  }

  return identifiers;
};

export const getThirdPartyIdentifiers = (): ThirdPartyIdentifiers => {
  try {
    const configData = configStateStore.getState();
    if (!configData.flags?.includes(IDENTITY_RESOLUTION_FLAG)) {
      return EMPTY_IDENTIFIERS;
    }

    const identifiers = getKlaviyoIds();
    const shopifyCookie = getCookie(SHOPIFY_COOKIE_NAME);
    if (shopifyCookie) {
      identifiers.shopify_y = shopifyCookie;
    }

    return identifiers;
  } catch {
    return EMPTY_IDENTIFIERS;
  }
};

export const isIdentifiedByThirdPartyCookie = (
  identifiers: ThirdPartyIdentifiers,
): boolean => Object.values(identifiers).some((id) => !!id);
