import { html } from 'lit';
import { DEFAULT_TERMS } from '../../helpers/constants';
import checkoutStyles from './checkoutStyles';
import phoneInput from './phoneInput';

const checkout = ({
  checked,
  label,
  handleOptInClicked,
  hostname,
  shouldRenderPhoneInput,
}) => html`
  ${checkoutStyles}
  <div id="ps__container" class="section__content shopify">
    <div
      id="ps__checkbox_container"
      class="fieldset-description"
      data-cy="checkbox-container"
    >
      <div class="section__content">
        <div class="checkbox-wrapper">
          <div class="checkbox__input">
            <input
              id="ps_accepts_sms"
              name="ps_accepts_sms"
              aria-labelledby="ps_accepts_checkbox_label"
              type="checkbox"
              class="input-checkbox"
              ?checked=${checked}
              @change=${({ target: { checked } }) =>
                handleOptInClicked(checked)}
              data-cy="accepts-sms-checkbox"
            />
          </div>
          <span
            id="ps_accepts_checkbox_label"
            name="ps_accepts_checkbox_label"
            class="checkbox__label"
            data-cy="accepts-sms-txt"
            @click=${() => document.getElementById('ps_accepts_sms').click()}
            >${label}</span
          >
        </div>
      </div>
    </div>
    <div id="ps__footer">${DEFAULT_TERMS(hostname)}</div>
    ${shouldRenderPhoneInput ? phoneInput : ''}
  </div>
`;

export default checkout;
