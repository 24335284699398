import { html } from 'lit';
import { DEFAULT_TERMS } from '../../helpers/constants';
import checkoutStyles from './checkoutStyles';
import phoneInputRecharge from './phoneInputRecharge';

const checkoutRecharge = ({
  checked,
  label,
  handleOptInClicked,
  hostname,
  shouldRenderPhoneInput,
}) => html`
  ${checkoutStyles}
  <div
    class="recharge"
    id="ps__container"
    style="padding: 2px; margin-bottom: 30px"
  >
    <label class="buyer_accepts_marketing" for="ps_accepts_sms">
      <input
        id="ps_accepts_sms"
        name="ps_accepts_sms"
        type="checkbox"
        ?checked=${checked}
        @change=${({ target: { checked } }) => handleOptInClicked(checked)}
        data-cy="accepts-sms-checkbox"
      />
      <span style="margin-left: 0.25em;">${label}</span>
    </label>
    <div
      id="ps__footer"
      style="
      padding-top: 1em;
      line-height: 1.2;
      font-size: 10px;
      color: rgb(85, 85, 85);
    "
    >
      ${DEFAULT_TERMS(hostname)}
    </div>
    ${shouldRenderPhoneInput ? phoneInputRecharge : ''}
  </div>
`;

export default checkoutRecharge;
