import { SHOPIFY_EXTENSION_LOCAL_STORAGE_KEY } from './constants';

// Our checkout UI extension can't set cookies directly, but it can use localStorage.
// So we set the subscriber id/token in localStorage under an extensionData key, and
// read them back out here to set the appropriate cookies.
export const identifySubscriberFromCheckoutExtension = (localStorageKey) => {
  const extensionStorage = localStorage.getItem(
    SHOPIFY_EXTENSION_LOCAL_STORAGE_KEY,
  );

  if (!extensionStorage) {
    return null;
  }

  const parsedData = JSON.parse(extensionStorage);

  // Shopify prepends a unique gid to the local storage items, so we can't directly look them up by key
  return Object.entries(parsedData).find((entry) =>
    entry[0].endsWith(localStorageKey),
  )?.[1];
};
