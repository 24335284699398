import { html } from 'lit';

const phoneInput = html`
  <div
    id="ps__phone_fieldset"
    style="margin-top: 20px;"
    class="fieldset"
    hidden
  >
    <div id="ps__phone_container" class="field field--optional phone">
      <label for="ps_phone_number">
        <span>Phone</span>
      </label>
      <input
        id="ps_phone_number"
        name="ps_phone_number"
        type="tel"
        class="optional"
      />
    </div>
  </div>
`;

export default phoneInput;
