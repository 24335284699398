import { html } from 'lit';

const phoneInput = html`
  <div
    id="ps__phone_container"
    style="marginTop: 1em;"
    class="buyer-phone-number input-wrap"
    hidden
  >
    <input
      id="ps_phone_number"
      name="ps_phone_number"
      type="tel"
      placeholder="Phone Number"
      class="form-control ch-input input-shipping-first-name"
      @change=${({ target: { value } }) => {
        const self = document.getElementById('ps_phone_number');
        if (value) return self.classList.add('ch-dirty');
        return self.classList.remove('ch-dirty');
      }}
    />
    <label for="ps_phone_number" class="input-label"
      ><span data-ch-type="text">Phone Number</span></label
    >
  </div>
`;

export default phoneInput;
