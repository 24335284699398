/* eslint-disable import/no-restricted-paths */
import {
  SUBSCRIBER_ID_COOKIE_NAME,
  V2_OPT_IN_SOURCES,
} from '../../../helpers/constants';
import { setCookie } from '../../../helpers/utility';
import { PublicSdk } from '../types';
import { optIn, resendOTP, validateOTP } from './service';
import { OnsiteVerifyRequest } from './types';

const registerOnsiteOptInSdk = (
  postscriptObject: Partial<PublicSdk>,
  shopId: number,
) => {
  // eslint-disable-next-line no-param-reassign
  postscriptObject.acquisition = {
    optIn: async (phoneNumber: string) => {
      const data = await optIn({
        phoneNumber,
        shopId,
        optInMethod: 'ONE_TIME_PASSCODE',
        source: V2_OPT_IN_SOURCES.THIRD_PARTY,
      });

      if (!data.success) {
        throw new Error('Opt-in operation failed');
      }

      if (data?.subscriberId) {
        return {
          subscriberStatus: 'subscribed',
          subscriberId: data.subscriberId,
        };
      }

      return {
        subscriberStatus: 'pending',
        subscriberId: data.token,
      };
    },
    verify: async ({ code, phoneNumber }: OnsiteVerifyRequest) => {
      const response = await validateOTP(shopId, phoneNumber, code);

      if (!response?.data?.success) {
        throw new Error('Verification operation failed');
      }

      setCookie(SUBSCRIBER_ID_COOKIE_NAME, response.data.subscriber_id);
      return {
        success: response.data.success,
        subscriberId: response.data.subscriber_id,
      };
    },
    resendOtp: async (phoneNumber: string) => {
      const response = await resendOTP(shopId, phoneNumber);

      if (!response?.success) {
        throw new Error('Resend operation failed');
      }

      if (response?.subscriber_id) {
        setCookie(SUBSCRIBER_ID_COOKIE_NAME, response?.subscriber_id);
      }
    },
  };
};

export default registerOnsiteOptInSdk;
