export const canUserBeTracked = (
  shopifyGlobal?: ShopifyGlobals,
): Promise<boolean> =>
  new Promise((resolve) => {
    let isResolved = false;
    if (!shopifyGlobal?.loadFeatures) {
      resolve(true);
      return;
    }

    try {
      shopifyGlobal?.loadFeatures?.(
        [
          {
            name: 'consent-tracking-api',
            version: '0.1',
          },
        ],
        (error: string | null) => {
          isResolved = true;

          if (error) {
            // default behavior is to continue tracking if we can't load consent
            resolve(true);
            return;
          }
          const canBeTracked =
            !shopifyGlobal?.customerPrivacy ||
            shopifyGlobal.customerPrivacy.userCanBeTracked();

          resolve(canBeTracked);
        },
      );
    } catch {
      resolve(true);
    }

    /**
     * We've seen a bug in production where Shopify.loadFeatures exists but the
     * callback is never called (meaning our promise is never resolved).
     * This timeout prevents the promise from hanging indefinitely.
     */
    setTimeout(() => {
      if (!isResolved) {
        resolve(true);
      }
    }, 3000);
  });
