/* eslint-disable import/no-restricted-paths */
/* eslint-disable camelcase */
import { configureSubscriptionEnvironment } from '../../../helpers/events';
import {
  BlockPopupOptInProps,
  BlockPopupOptInResponse,
  OptInProps,
  OptInResponseData,
  ResendOTPResponse,
  VerifyResponse,
} from '../../../helpers/types';
import { CLOUDFLARE_ENTERPRISE_PLAN_URL } from '../../../helpers/utility';
import { calculateFingerprintId } from '../../../helpers/fingerprint';

/**
 * Used for block popup opt-in method
 */
export const blockPopupOptIn = async ({
  country,
  phoneNumber,
  popupId,
  sessionId,
  shopId,
  source,
}: BlockPopupOptInProps): Promise<BlockPopupOptInResponse> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_ENTERPRISE_PLAN_URL}/v2/public/block_popups/opt_in`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          country,
          popup_id: popupId,
          shop_id: shopId,
          phone_number: phoneNumber,
          source,
          session_id: sessionId,
        }),
      },
    );

    if (!response.ok) throw new Error();

    const {
      subscriber_id: subscriberId,
      success,
      token,
    }: OptInResponseData = await response.json();

    // Set various cookies
    configureSubscriptionEnvironment({ subscriberId, token });

    return {
      subscriberId,
      success,
      token,
    };
  } catch (error) {
    return { success: false };
  }
};

/**
 * Used by v2 OTP and double opt-in opt-in methods
 */
export const optIn = async ({
  countryCode,
  id,
  phoneNumber,
  sessionId,
  shopId,
  source,
  optInMethod,
}: OptInProps) => {
  // When user first opts in, we need to calculate the fingerprintId
  const fingerprintId = await calculateFingerprintId();
  try {
    const response = await fetch(
      `${CLOUDFLARE_ENTERPRISE_PLAN_URL}/api/subscriber/opt_in`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          popup_id: id,
          shop_id: shopId,
          phone_number: phoneNumber,
          country_code: countryCode,
          source,
          session_id: sessionId,
          email: window.ps__email,
          opt_in_method: optInMethod,
          fingerprint: [
            {
              version: 2,
              data: fingerprintId,
            },
          ],
        }),
      },
    );

    if (!response.ok) throw new Error();

    const {
      subscriber_id: subscriberId,
      success,
      token,
    }: OptInResponseData = await response.json();

    // Set various cookies
    configureSubscriptionEnvironment({ subscriberId, token });

    return {
      subscriberId,
      success,
      token,
    };
  } catch (error) {
    return { success: false };
  }
};

export const validateOTP = async (
  shopId: number,
  phoneNumber: string | undefined,
  code: string,
): Promise<VerifyResponse> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_ENTERPRISE_PLAN_URL}/v2/opt_in/one_time_passcode/verify`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          shop_id: shopId,
          phone_number: phoneNumber,
          code,
        }),
      },
    );

    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(`Error validating code: ${error.message}`);
    } else if (typeof error === 'string') {
      // eslint-disable-next-line no-console
      console.error(`Error validating code: ${error}`);
    } else {
      // eslint-disable-next-line no-console
      console.error('Error validating code');
    }
    return null;
  }
};

/**
 * Used by block and v2 popups
 */
export const resendOTP = async (
  shopId: number,
  phoneNumber: string,
): Promise<ResendOTPResponse> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_ENTERPRISE_PLAN_URL}/v2/opt_in/one_time_passcode/resend`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          shop_id: shopId,
          phone_number: phoneNumber,
        }),
      },
    );

    const { success, subscriber_id }: ResendOTPResponse = await response.json();

    return {
      success,
      error: null,
      subscriber_id,
    };
  } catch (error) {
    return {
      success: false,
      error,
      subscriber_id: null,
    };
  }
};
