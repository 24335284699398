type ShopifyWindow = typeof window & {
  Shopify?: {
    shop?: string;
    Checkout?: {
      apiHost?: string;
    };
  };
  CHDataObject?: {
    store_urls?: {
      store_url?: string;
    };
  };
  BOLD?: {
    config?: {
      shop_url?: string;
    };
  };
};

export const getMyShopifyDomain = (): string | null => {
  try {
    const url = new URL(window.location.href);
    let myShopifyDomain = url.searchParams.get('myshopify_domain');

    const win = window as unknown as ShopifyWindow;

    if (!myShopifyDomain && win.Shopify?.shop) {
      myShopifyDomain = win.Shopify.shop;
    }
    if (!myShopifyDomain && win.Shopify?.Checkout?.apiHost) {
      myShopifyDomain = win.Shopify.Checkout.apiHost;
    }
    if (!myShopifyDomain && win.CHDataObject?.store_urls?.store_url) {
      myShopifyDomain = win.CHDataObject.store_urls.store_url;
    }
    if (!myShopifyDomain && win.BOLD?.config?.shop_url) {
      myShopifyDomain = win.BOLD.config.shop_url;
    }

    return myShopifyDomain ?? null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};
