import { render } from 'lit';
import checkout from './checkout/checkout';
import checkoutCarthook from './checkout/checkoutCarthook';
import checkoutRecharge from './checkout/checkoutRecharge';
import { setCookie, getCookie } from '../helpers/utility';
import {
  DEFAULT_OPT_IN_LABEL,
  OPT_IN_CHECKED_COOKIE_NAME,
} from '../helpers/constants';

const showPhoneInput = (show) => {
  const phoneContainer = document.getElementById('ps__phone_container');
  const phoneInput = document.getElementById('ps_phone_number');

  if (!phoneContainer || !phoneInput) return;

  phoneContainer.hidden = !show;
  if (show) phoneInput.focus();
};

const handleOptInClicked = (checked) => {
  setCookie(OPT_IN_CHECKED_COOKIE_NAME, checked, 1);
  showPhoneInput(checked);
};

const getOptInChecked = () => getCookie(OPT_IN_CHECKED_COOKIE_NAME) === 'true';

const checkoutMap = {
  shopify_checkout: checkout,
  carthook_checkout: checkoutCarthook,
  recharge: checkoutRecharge,
};

const init = ({
  checkoutType = 'shopify_checkout',
  hostname,
  shouldRenderPhoneInput, // if the checkout doesn't include a phone input, we need to render our own
  psConfig,
}) => {
  // TODO: there are some page types that we dont' account for
  // We should report these in sentry in the future
  const component = checkoutMap[checkoutType] || checkout;

  const ROOT_SELECTOR = 'ps__container_root';
  const rootElement = document.getElementById(ROOT_SELECTOR);

  // Defensive coding: causing issue with customers
  if (!rootElement) return;

  const checked = getOptInChecked();

  render(
    component({
      checked,
      handleOptInClicked,
      hostname,
      label: psConfig.textAgreement || DEFAULT_OPT_IN_LABEL,
      shouldRenderPhoneInput,
    }),
    rootElement,
  );

  // the rendered component includes the phone input, so we need to do this after render
  if (checked && shouldRenderPhoneInput) showPhoneInput(true);
};

export default init;
