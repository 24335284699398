import { EVENT_NAME } from './constants';

export const publishPostscriptReadyEvent = () => {
  window.dispatchEvent(
    new CustomEvent(EVENT_NAME, {
      detail: {},
      bubbles: true,
      cancelable: true,
    }),
  );
};
