import { SdkConfig } from '../sdk/subscriber-events/utils';
import { MayHaveSplitTest } from '../services/popup/types';
import { SPLIT_TEST_STATUS_COOKIE_NAME_PREFIX } from './constants';
import { setCookieOnParentDocument } from './iframe';
import { CLOUDFLARE_BUSINESS_PLAN_URL } from './utility';

export const fetchConfig = async (
  shopId: string | null,
  shopDomain?: string,
): Promise<SdkConfig | null> => {
  const apiEndpoint = shopId
    ? `${CLOUDFLARE_BUSINESS_PLAN_URL}/sdk/config?shop_id=${shopId}`
    : `${CLOUDFLARE_BUSINESS_PLAN_URL}/sdk/config?shop_domain=${shopDomain}`;

  try {
    const response = await fetch(apiEndpoint);
    return response.ok ? response.json() : null;
  } catch {
    return null;
  }
};

export const groupPopupsBySplitTest = <T extends MayHaveSplitTest>(
  popupsWithDefaults: Array<T>,
): Record<string | number, T[]> => {
  if (!Array.isArray(popupsWithDefaults)) {
    return {};
  }

  const splitTestDict: Record<string | number, T[]> = {};

  popupsWithDefaults.forEach((popup) => {
    if (popup.splitTest) {
      if (!splitTestDict[popup.splitTest.split_test_id]) {
        splitTestDict[popup.splitTest.split_test_id] = [popup];
      } else {
        splitTestDict[popup.splitTest.split_test_id].push(popup);
      }
    }
  });

  return splitTestDict;
};

/**
 * Returns the status value for a given key from a list of cookie statuses
 * as a string in the following format: "_ps_pop_123:r;_ps_pop_234:a"
 *
 * @param {int} key - ID of key to check status for
 * @param {string} statuses - List of statuses in string form.  Should be in the following format
 *  "_ps_pop_123:r;_ps_pop_234:a";
 * @returns {string} - String status for key
 */
export const getCookieValueByKey = (
  prefix: string,
  key: number | string,
  statuses: string | null,
) => {
  if (!statuses) {
    return;
  }

  const statusTokens = statuses.split(';');

  const popupIdStatusPair = statusTokens
    .filter((item) => !!item)
    .map((item) => item.split(':'))
    .find(([popupIdWithPrefix]) => {
      const popupId = popupIdWithPrefix.split(prefix)[1];
      return popupId === `${key}`;
    });

  if (!popupIdStatusPair) {
    return;
  }

  return popupIdStatusPair[1];
};

export const findCookiedPopupForSplitTest = <T extends MayHaveSplitTest>(
  splitTestId: string | number,
  splitTestCookies: string | null,
  popupsInSplitTest: T[],
) => {
  const cookiedPopupId = getCookieValueByKey(
    SPLIT_TEST_STATUS_COOKIE_NAME_PREFIX,
    splitTestId,
    splitTestCookies,
  );

  return popupsInSplitTest.find((popup) => `${popup.id}` === cookiedPopupId);
};

export const pickSplitTestByWeight = <T extends MayHaveSplitTest>(
  splitTestDict: Record<string | number, T[]>,
  splitTestCookies: string | null,
) => {
  // random value between 1-100
  const splitRandomValue = Math.floor(Math.random() * 100) + 1;
  const splitTestPopupsToInclude: T[] = [];

  Object.entries(splitTestDict).forEach(([splitTestId, popupsInSplitTest]) => {
    const cookiedPopup = findCookiedPopupForSplitTest(
      splitTestId,
      splitTestCookies,
      popupsInSplitTest,
    );
    if (cookiedPopup) {
      splitTestPopupsToInclude.push(cookiedPopup);
      return;
    }

    let startWeight = 0;
    popupsInSplitTest.forEach((popup) => {
      const endWeight = (popup.splitTest?.weight ?? 0) + startWeight;
      if (startWeight < splitRandomValue && splitRandomValue <= endWeight) {
        splitTestPopupsToInclude.push(popup);
        setCookieOnParentDocument(
          `${SPLIT_TEST_STATUS_COOKIE_NAME_PREFIX}${splitTestId}`,
          popup.id,
          3650,
        );
      }
      startWeight += popup.splitTest?.weight ?? 0;
    });
  });

  return splitTestPopupsToInclude;
};

type GetExternalResourceLinksOptions = Partial<
  Pick<HTMLLinkElement, 'as' | 'href' | 'rel'>
>;

export const getExternalResourceLinks = (
  urls: string[],
  options: GetExternalResourceLinksOptions = {},
) =>
  urls.map((url) => {
    const link = document.createElement('link');
    const propertyEntries = Object.entries({
      ...options,
      href: url,
    }) as [keyof GetExternalResourceLinksOptions, string][];

    propertyEntries.forEach(([key, value]) => {
      link[key] = value;
    });

    return link;
  });

export const preloadImages = (urls: (string | undefined)[] = []) => {
  const definedUrls = urls.filter((url) => !!url) as string[];
  const links = getExternalResourceLinks(definedUrls, {
    as: 'image',
    rel: 'preload',
  });

  links.forEach((link) => {
    const existingLink = document.head.querySelector(
      `link[href="${link?.href}"]`,
    ) as HTMLLinkElement;
    if (!existingLink && link) document.head.appendChild(link);
  });
};
