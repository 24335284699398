import {
  POPUP_HARD_CLOSE_STATE,
  POPUP_SOFT_CLOSE_STATE,
} from '../../../helpers/constants';
import { EngagementTracker } from '../../../helpers/engagementTracker';
import { Disclaimer } from '../../../sdk/subscriber-events/utils';

export const POPUP_STATUS_FRIENDLY_NAMES = {
  SOFT_CLOSE: 'softClose',
  HARD_CLOSE: 'hardClose',
  OPEN: 'open',
} as const;

export type PopupStatusFriendlyNames =
  typeof POPUP_STATUS_FRIENDLY_NAMES[keyof typeof POPUP_STATUS_FRIENDLY_NAMES];

export interface ActivePopupContext {
  id: number;
  status: PopupStatusFriendlyNames;
  engagementTracker: EngagementTracker;
}

export interface ConfigContext {
  flags: string;
  shopId: number;
  shopShop: string;
  disclaimer: Disclaimer;
  isPreview: boolean;
}

export const getFriendlyNameForPopupStatus = (status?: string) => {
  switch (status) {
    case POPUP_SOFT_CLOSE_STATE:
      return POPUP_STATUS_FRIENDLY_NAMES.SOFT_CLOSE;
    case POPUP_HARD_CLOSE_STATE:
      return POPUP_STATUS_FRIENDLY_NAMES.HARD_CLOSE;
    default:
      return POPUP_STATUS_FRIENDLY_NAMES.OPEN;
  }
};

class Store<StateType> {
  private state: Partial<StateType> = {};

  updateState(state: Partial<StateType>) {
    this.state = {
      ...this.state,
      ...state,
    };
  }

  getState() {
    return this.state;
  }

  reset() {
    this.state = {};
  }
}

export const popupStateStore = new Store<ActivePopupContext>();
export const configStateStore = new Store<ConfigContext>();
