import { PS_POPUP_IFRAME_ID } from '../../../helpers/constants';
import { PublicSdk } from '../types';
import {
  ACTIVE_POPUP_BEHAVIORS,
  CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE,
} from './constants';
import { ActivePopupBehaviors, PopupOpenOptions } from './types';

const DEFAULT_OPTIONS: PopupOpenOptions = {
  activePopupBehavior: ACTIVE_POPUP_BEHAVIORS.ALWAYS_DISMISS,
  respectPopupStatus: true,
};

const isActivePopupBehavior = (
  activePopupBehavior?: ActivePopupBehaviors,
): activePopupBehavior is ActivePopupBehaviors => {
  if (!activePopupBehavior) {
    return false;
  }

  if (!Object.values(ACTIVE_POPUP_BEHAVIORS).includes(activePopupBehavior)) {
    // eslint-disable-next-line no-console
    console.error(
      `Invalid activePopupBehavior ${activePopupBehavior}. Valid values are ${Object.values(
        ACTIVE_POPUP_BEHAVIORS,
      ).join(', ')}. Default behavior will be used instead.`,
    );
    return false;
  }

  return true;
};

const isBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean';

const registerPopupSdk = (postscriptObject: Partial<PublicSdk>): void => {
  // eslint-disable-next-line no-param-reassign
  postscriptObject.popups = {
    open: (id: number, options: Partial<PopupOpenOptions> = {}) => {
      const iframe = document.getElementById(
        PS_POPUP_IFRAME_ID,
      ) as HTMLIFrameElement;

      if (!iframe?.contentWindow) {
        // eslint-disable-next-line no-console
        console.error('Postscript popup container was not found.');
        return;
      }

      iframe.contentWindow.postMessage(
        {
          type: CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE,
          id,
          options: {
            ...DEFAULT_OPTIONS,
            activePopupBehavior: isActivePopupBehavior(
              options.activePopupBehavior,
            )
              ? options.activePopupBehavior
              : DEFAULT_OPTIONS.activePopupBehavior,
            respectPopupStatus: isBoolean(options.respectPopupStatus)
              ? options.respectPopupStatus
              : DEFAULT_OPTIONS.respectPopupStatus,
          },
        },
        '*',
      );
    },
  };
};

export default registerPopupSdk;
